<template>
  <div>
    <div v-if="loading" class="pa-0 pa-sm-6">
      <v-skeleton-loader
        type="list-item-avatar, divider, list-item-three-line, card-heading, image, actions"
      ></v-skeleton-loader>
    </div>
    <div v-else class="pa-0 pa-sm-6">
      <div class="mx-2 mx-sm-6">
        <v-btn depressed color="white" class="lowShadow" @click="voltar()">
          <v-icon>mdi-arrow-left</v-icon>Voltar
        </v-btn>
      </div>

      <v-card class="cardIndex mx-2 mx-sm-6 mt-6 lowShadow rounded-lg">
        <div class="actionsHeader">
          <div class="ma-1 ml-0">
            <v-btn
              :color="turma.disableNotification ? '' : 'secondary'"
              text
              title="Habilita/Desabilita o envio de notificação para os integrantes da turma"
              @click="toggleNotification()"
            >
              <div v-if="turma.disableNotification">
                <span class="mr-1">Desativar notificação</span>
                <v-icon> mdi-bell-off </v-icon>
              </div>
              <div v-else class="flex flex-row items-center">
                <span class="mr-1">Ativar Notificação</span>
                <v-icon size="16">mdi-bell</v-icon>
              </div>
            </v-btn>
          </div>
          <div class="ma-1 ml-0">
            <v-btn
              color="secondary"
              text
              title="Configurações"
              @click="openConfigModal()"
            >
              <span class="mr-1">
                Configurações
              </span>
              <v-icon>mdi-cog-outline</v-icon>
            </v-btn>
          </div>
          <div class="ma-1 ml-0">
            <v-btn
              color="secondary"
              text
              title="Acessar turma/evento"
              @click="acessoAdmin()"
            >
              <span class="mr-1">
                Acessar
              </span>
              <v-icon>mdi-school-outline</v-icon>
            </v-btn>
          </div>
        </div>

        <div class="grey--text pa-3 grid grid-cols-2">
          <div class="text-center">
            Código: <span class="font-weight-bold">EVT-{{ turma.id }}</span>
          </div>
          <div class="text-center">
            Categoria:
            <span class="font-weight-bold">
              {{ turma.tipoEvento | getNameTypeEvent }}
            </span>
          </div>
        </div>

        <div
          style="display: grid; grid-template-columns: 1fr 1fr;justify-items: center; align-items: center"
        >
          <div style="width: 100%">
            <div
              v-if="!existePacote"
              style="width: 80%;margin: 0 auto;  padding: 20px; border-radius: 10px"
            >
              <div class="selecionePacoteTitulo">
                Você ainda não possui um pacote
              </div>
              <div class="mt-3 text-center">
                <button class="customBtn" @click="dialogSelectPacote = true">
                  Selecionar pacote
                </button>
              </div>
            </div>
            <div v-else class="text-center">
              <div class="tituloPacote text-slate-700">
                Pacote Ativo
              </div>
              <div class="descPacote text-slate-700">
                {{ turma.pacoteTitulo }}
              </div>
            </div>
          </div>
          <div>
            <ConviteTurma :convite="turma.codigoConvite" />
          </div>
        </div>

        <div
          v-if="turma.tipoEvento == getEvento.FORMATURA"
          id="agrupador-evento"
          class="mx-2 mx-sm-6"
        >
          <v-autocomplete
            v-model="eventoSelected"
            :items="listaCompletaEventos"
            :search-input.sync="searchEvento"
            color="primary"
            label="Grupo de Evento"
            item-text="nome"
            item-value="id"
            placeholder="Encontre seu agrupmaento de evento"
            return-object
            chips
            class="text-truncate"
            :value="eventoSelected"
            @change="setChangeEvento"
          >
            <template v-slot:selection="data">
              <div>
                <v-chip>
                  {{ data.item.nome }}
                </v-chip>
              </div>
            </template>
            <template v-slot:item="data">
              <template>
                <v-list-item-icon>
                  <div>
                    <v-icon
                      color="rgba(50,50,50,0.2)"
                      v-text="'mdi-school'"
                    ></v-icon>
                  </div>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    v-html="data.item.nome"
                  ></v-list-item-title>
                </v-list-item-content>
              </template>
            </template>
            <template slot="no-data">
              <div v-if="addEvento == false" class="pa-2">
                Digite para adicionar ou encontrar seu grupo de evento
              </div>
              <div v-if="addEvento == true" class="pa-2">
                <small>
                  Não encontramos o seu evento, deseja adicionar:
                </small>
                <br />
                <div class="pa-1">
                  <strong>{{ searchEvento }}</strong>
                </div>
                <v-btn
                  color="primary"
                  class="pa-1 ma-1"
                  @click="setNovoEvento()"
                >
                  confirmar evento
                </v-btn>
              </div>
            </template>
          </v-autocomplete>
        </div>

        <vModuleCasamento
          v-if="turma.tipoEvento == getEvento.CASAMENTO"
          :turma="turma"
          @close="voltar"
        />
        <vModuleFormatura
          v-if="turma.tipoEvento == getEvento.FORMATURA"
          :turma="turma"
          @close="voltar"
        />
        <vModuleCorporativo
          v-if="turma.tipoEvento == getEvento.CORPORATIVO"
          :turma="turma"
          @close="voltar"
        />
        <vModuleAssociacao
          v-if="turma.tipoEvento == getEvento.ASSOCIACAO"
          :turma="turma"
          @close="voltar"
        />
        <vModuleOutro
          v-if="turma.tipoEvento == getEvento.OUTRO"
          :turma="turma"
          @close="voltar"
        />
      </v-card>
    </div>
    <ConfiguracaoEvento
      v-if="modalConfigModal"
      :turma="turma"
      @close="closeConfigModal"
    />
    <vSelecionePacote
      v-if="dialogSelectPacote"
      :turma-id="getTurmaId"
      @confirm="concluirProcessoSelecionarPacote()"
      @close="dialogSelectPacote = false"
    />
  </div>
</template>

<script>
import serviceTurma from "@/services/turma";
import serviceTipos from "@/services/tiposController";
import serviceRelatorios from "@/services/relatorios";
import serviceEventos from "@/services/eventos";
import moment from "moment";
import codeID, { getNames } from "@/constantes/EventosType";
import { URL_APP } from "@/constantes/base";
import vModuleFormatura from "./modulos/ModuloFormatura";
import vModuleCasamento from "./modulos/ModuloCasamento.vue";
import vModuleCorporativo from "./modulos/ModuloCorporativo.vue";
import vModuleOutro from "./modulos/ModuloOutros.vue";
import vModuleAssociacao from "./modulos/ModuloAssociacao.vue";
import ConviteTurma from "./ConviteTurma.vue";
import ConfiguracaoEvento from "./ConfiguracaoEvento.vue";
import vSelecionePacote from "./SelecionePacote.vue";

export default {
  components: {
    vModuleFormatura,
    vModuleCasamento,
    vModuleCorporativo,
    vModuleOutro,
    vModuleAssociacao,
    ConviteTurma,
    ConfiguracaoEvento,
    vSelecionePacote
  },
  filters: {
    dataReal(val) {
      if (val) return moment(val).format("DD/MM/YYYY");
      else return "S/REGISTRO";
    },
    getNameTypeEvent(val) {
      if (val && getNames[val]) {
        return getNames[val];
      } else {
        return val;
      }
    }
  },
  data() {
    return {
      turma: {},
      dialogSelectPacote: false,
      modalConfigModal: false,
      addEvento: false,
      eventoSelected: null,
      listaCompletaEventos: [],
      listaIncludesEventos: [],
      listaBuscaEventos: [],
      timeSearch: 300,
      searchEvento: "",
      loading: true,
      dialog: true,
      errorToggle: false,
      errorData: {},
      tiposCalc: [],
      plano: {},
      filtros: {
        turma: null,
        dataInicial: null,
        dataFinal: null,
        tipoRel: "PDF"
      }
    };
  },
  computed: {
    existePacote() {
      if (this.turma && this.turma.pacoteTitulo && this.turma.pacoteId) {
        return true;
      }
      return false;
    },
    getTurmaId() {
      return this.$route.params.id;
    },
    getEvento() {
      return codeID;
    },
    getLinkConvite() {
      return (
        "https://app.appdaturma.com.br/site/cadastro/" +
        this.turma.codigoConvite
      );
    }
  },
  watch: {
    listaBuscaEventos() {
      let itensAtivos = this.listaIncludesEventos;
      let itensSearch = this.listaBuscaEventos;
      this.listaCompletaEventos = itensSearch.concat(itensAtivos);
    },
    searchEvento() {
      this.debounceEvent();
    }
  },
  beforeMount() {
    const EventId = this.$route.params.id;
    if (EventId) {
      this.loadStartData(EventId);
    } else {
      this.$router.replace("/dashboard/turmas/lista");
    }
  },
  mounted() {
    this.buscaTipos();
  },
  methods: {
    concluirProcessoSelecionarPacote() {
      const EventId = this.$route.params.id;
      this.loading = true;
      this.loadStartData(EventId);
      this.dialogSelectPacote = false;
    },
    debounceEvent() {
      clearTimeout(this._timerId);
      let search = this.searchEvento;
      search = search ? search.trim() : null;
      this._timerId = setTimeout(() => {
        if (!search) {
          return false;
        }
        serviceEventos
          .searchEvent(search)
          .then(resp => {
            if (resp.status == 200) {
              this.listaBuscaEventos = resp.data;
              if (resp.data.length < 1 && search.length > 0) {
                this.addEvento = true;
              } else {
                this.addEvento = false;
              }
            }
          })
          .catch(err => {
            console.error(err);
          });
      }, this.timeSearch);
    },
    setNovoEvento() {
      let payload = {
        nome: this.searchEvento.trim(),
        id: 0
      };
      serviceEventos
        .createEvent(payload)
        .then(() => {
          this.debounceEvent();
        })
        .catch(err => {
          this.$root.showError(err.data);
        });
    },
    setChangeEvento(value) {
      if (value) {
        this.listaIncludesEventos = [{ ...value }];
        this.turma.eventoId = value.id;
        this.turma.eventoNome = value.nome;
      } else {
        this.turma.eventoId = null;
        this.turma.eventoNome = null;
      }
    },
    toggleNotification() {
      this.loading = true;
      serviceTurma
        .postDisableNotification(this.turma.id, !this.turma.disableNotification)
        .then(resp => {
          this.$root.showSucesso(resp.data);
          this.loadStartData(this.turma.id);
        })
        .catch(err => {
          this.$root.showError(err);
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    closeConfigModal() {
      this.modalConfigModal = false;
    },
    openConfigModal() {
      this.modalConfigModal = true;
    },
    acessoAdmin() {
      const turmaID = this.turma.id;
      const token = localStorage.getItem("APT-ADMTOKEN");
      window.open(
        `${URL_APP}/redirect-auth?token=${token}&turma=${turmaID}`,
        "_black"
      );
    },
    voltar() {
      this.$router.replace("/dashboard/turmas/lista");
    },
    async preCarregaLista() {
      let search = this.turma.eventoNome;
      await serviceEventos
        .searchEvent(search)
        .then(resp => {
          if (resp.status == 200) {
            this.listaCompletaEventos = resp.data;
          }
        })
        .catch(err => {
          console.error(err);
        });
      this.eventoSelected = {
        agenciaId: this.listaCompletaEventos[0].agenciaId,
        id: this.turma.eventoId,
        nome: this.turma.eventoNome
      };
    },
    /**
     * Usado em alguns momentos para o carregamento e atualização de dados em tela
     * contém this.loading(false) caso use anteriormente
     * @param {Number} EventId
     * Carrega os dados da turma
     */
    loadStartData(EventId) {
      serviceTurma
        .getTurmaById(EventId)
        .then(resp => {
          this.turma = resp.data;
          if (this.turma.eventoId) {
            this.preCarregaLista();
          }
          this.loading = false;
          this.buscaPlan();
        })
        .catch(e => {
          console.error(e);
        });
    },
    copiarLink() {
      let copyTextarea = document.querySelector("#codigoTurmaCopy");
      copyTextarea.focus();
      copyTextarea.select();
      try {
        var successful = document.execCommand("copy");
        if (successful) {
          this.$root.showInfo("Copiado com sucesso!");
        } else {
          this.$root.showError("Não foi possível copiar");
        }
      } catch (err) {
        this.$root.showError("Não foi possível copiar");
        console.error(err);
      }
    },
    abrirExtrato() {
      let turmaID = this.turma.id;
      let filtros = this.filtros;
      serviceRelatorios
        .extratoMovimentacao(turmaID, filtros)
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            response.headers["content-disposition"].split(";")[1].split("=")[1]
          );
          document.body.appendChild(link);
          link.click();
          this.$root.showInfo("Download do PDF concluído!");
        })
        .catch(err => {
          console.log(err);
        });
    },
    getCalcFormando(val) {
      if (!val) {
        return "Por quantidade do item";
      } else {
        return "Por quantidade de alunos";
      }
    },
    getTipoCalc(code) {
      return this.tiposCalc.find(item => item.id == code).descricao;
    },
    close() {
      this.$emit("close");
    },
    buscaTipos() {
      serviceTipos
        .tiposCalculo()
        .then(resp => {
          this.tiposCalc = resp.data;
        })
        .catch(err => {
          console.log(err);
        });
    },
    buscaPlan() {
      let turmaID = this.turma.id;
      serviceTurma
        .getPlano(turmaID)
        .then(resp => {
          this.plano = resp.data;
        })
        .finally(() => {})
        .catch(err => {
          this.errorToggle = true;
          if (err.status == 400) {
            this.errorData = { mensagem: err.data, status: err.status };
          } else {
            this.errorData = { mensagem: err.data, status: err.status };
            this.$root.showError(err.data);
          }
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.selecionePacoteTitulo {
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  margin-bottom: 20px;
  color: rgb(94, 107, 121);
}
.customBtn {
  color: rgba(250, 250, 250, 1);
  font-weight: 300;
  font-size: 16px;
  padding: 8px 20px;
  border-radius: 2px;
  background-color: #37447e;
  &:hover {
    background-color: #3a4888;
  }
}
.actionsHeader {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  flex-wrap: wrap;
  padding: 5px;
  background-color: rgba(50, 80, 200, 0.05);

  @media screen and (max-width: 600px) {
    justify-content: center;
  }
}

.containPacote {
  text-align: center;
  margin: 10px 10px;
}

.tituloPacote {
  font-size: 13px;
  text-transform: uppercase;
}

.descPacote {
  font-size: 17px;
  padding: 10px 16px;
  border: 1px solid rgba(50, 50, 70, 0.2);
  color: #7275ff;
  border-radius: 5px;
  margin-top: 5px;
  font-weight: bold;
  display: inline-block;
}

.contemConvite {
  padding: 10px;
  background-color: rgba(50, 50, 70, 0.05);
  border-radius: 5px;
  margin-bottom: 10px;
}

.fraseParaConvite {
  padding: 5px;
  font-weight: 600;
  text-align: center;
}
</style>
